<template>
  <span class="authors-list">
    <span v-for="(author, index) in authors" :key="index">
      <span class="author" v-if="author.author === $static.myAuthorEntry.edges[0].node.name ">
        <b>
          <span>{{author.author}}</span>
        </b>
      </span>
      <span class="author" v-else>{{author.author}}</span>
      <span v-if="index == authors.length - 1">.&#32;</span>
      <span v-else>,&#32;</span>
    </span>
  </span>
</template>

<static-query>
query {
  myAuthorEntry: allAuthor(filter: {me:{eq:true}}){
    edges{
      node {
        me,
        name
      }
    }
  }
}
</static-query>

<script>
export default {
  name: "Authors",

  props: {
    authors: Array
  }  
}
</script>


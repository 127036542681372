<template>
  <Layout>
    <h1 class="title">Publications</h1>
    <span></span>
    <div
      class="publication"
      v-for="(publication, index) in $page.publications.edges"
      :key="index"
    >
      <p class="is-size-5">
        <span class="publication-title"
          >{{ publication.node.title }}.&#32;</span
        >
      </p>
      <p>
        <AuthorsComponent
          :authors="publication.node.authors"
        ></AuthorsComponent>
      </p>
      <p>
        <em>{{ publication.node.journal }}.&#32;</em>
        <span>{{ getYear(publication.node.date) }}&#32;</span>
      </p>
      <p>
        <span>
          Links:&#32;
          <a
            target="_blank"
            v-if="publication.node.link"
            :href="publication.node.link"
            >Publisher&#32;</a
          >
          <a
            target="_blank"
            v-if="publication.node.pubmedId"
            :href="
              'https://www.ncbi.nlm.nih.gov/pubmed/'.concat(
                publication.node.pubmedId
              )
            "
            >PubMed&#32;</a
          >
        </span>
      </p>
      <br />
    </div>
    <!--Presentations-->
    <h2 class="title">Presentations</h2>
    <div
      class="publication"
      v-for="(presentation, index) in $page.presentations.edges"
      :key="index"
    >
      <p class="is-size-5">
        <span class="publication-title"
          >{{ presentation.node.title }}.&#32;</span
        >
      </p>
      <p>
        <AuthorsComponent
          :authors="presentation.node.authors"
        ></AuthorsComponent>
      </p>
      <p>
        <em>{{ presentation.node.meeting }}.&#32;</em>
        <span>{{ presentation.node.type }} Presentation.&#32;</span>
        <span>{{ getYear(presentation.node.date) }}&#32;</span>
      </p>

      <br />
    </div>
  </Layout>
</template>

<page-query>
query  {
  publications: allPublication(sortBy: "date"){
    edges{
      node{
        title,
        date,
        journal,
        pubmedId,
        link,
        authors {
          author
        }

      }
    }
  }
  presentations: allPresentation(sortBy: "date"){
    edges{
      node{
        title,
        date,
        meeting,
        type,
        authors {
          author
        }

      }
    }
  }
}
</page-query>

<script>
import moment from "moment";

import AuthorsComponent from "@/components/publications/AuthorsComponent.vue";

export default {
  name: "Publications",
  metaInfo: {
    title: "Publications"
  },
  components: {
    AuthorsComponent
  },

  methods: {
    getYear(date) {
      const year = moment(date).year();
      return year;
    }
  }
};
</script>
